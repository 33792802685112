/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* fontface.less 2013-1-16 *******/
/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2024 MyFonts Inc. */
@font-face {
  font-family: "CenturyGothicItalic";
  src: url('/extras/fonts/CenturyGothicItalic/font.woff2') format('woff2'), url('/extras/fonts/CenturyGothicItalic/font.woff') format('woff');
}
@font-face {
  font-family: "CenturyGothicBoldItalic";
  src: url('/extras/fonts/CenturyGothicBoldItalic/font.woff2') format('woff2'), url('/extras/fonts/CenturyGothicBoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: "CenturyGothicRegular";
  src: url('/extras/fonts/CenturyGothicRegular/font.woff2') format('woff2'), url('/extras/fonts/CenturyGothicRegular/font.woff') format('woff');
}
@font-face {
  font-family: "CenturyGothicBold";
  src: url('/extras/fonts/CenturyGothicBold/font.woff2') format('woff2'), url('/extras/fonts/CenturyGothicBold/font.woff') format('woff');
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  margin: 0 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0;
  margin-bottom: 0;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #929093;
  color: #929093;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #454446 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #b3b3b3;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: normal;
  font-family: 'CenturyGothicBold', helvetica, sans-serif;
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #454446;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 5vw!important;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #454446;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: normal;
  font-family: 'CenturyGothicBold', helvetica, sans-serif;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #454446;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #626364;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #c5c4c5;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #6f7071;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #6f7071;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 1024px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: normal;
    font-family: 'CenturyGothicBold', helvetica, sans-serif;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(111, 112, 113, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  background-color: transparent;
  color: #454446;
  font-family: 'CenturyGothicRegular', helvetica, sans-serif;
  line-height: 1.2;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  width: 1200px;
  max-width: 100%;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.desk.navidesk {
  position: relative;
}
.navihome,
.cb-home {
  float: right;
  width: 259px;
  margin-top: 55px;
  margin-right: 0.66666667%;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
}
.layout3 .cb-home {
  display: none;
}
.header.cb-toggle-target-active .cb-home,
.header.cb-toggle-target-active .menupfad {
  opacity: 0;
}
.navihome {
  position: relative;
  z-index: 2;
  height: 55px;
}
.navilogo,
.logo {
  width: 100%;
  height: auto;
}
.l3home {
  float: right;
  width: 357px;
  margin: 38px 0.58333333% 9px 0;
  display: none;
}
.l3home .l3logo {
  width: 100%;
  height: auto;
}
.layout3 .l3home {
  display: block;
}
.cb-pages {
  float: left;
  width: 100%;
}
.cb-page {
  float: left;
  width: 100%;
}
.cb-page-title {
  float: left;
  width: 100%;
  margin-top: 34px;
  margin-bottom: 19px;
}
#wrapper {
  float: left;
  width: 100%;
  opacity: 0.001;
  transition: opacity 0.5s ease;
}
#wrapper.layout3 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  min-height: 100%;
}
.show-content #wrapper {
  opacity: 1;
}
.header {
  float: left;
  width: 100%;
  position: relative;
}
.layout3 .header {
  margin-top: 100px;
  background: url(/images/header.png) repeat 0 0;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 1;
}
.layout3 .header.cb-toggle-target-active {
  opacity: 0;
}
.menupfad {
  float: left;
  margin-left: 1.25%;
  margin-top: 83px;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
}
.layout3 .menupfad {
  margin-bottom: 15px;
  color: #fff;
}
.pfad {
  float: left;
  font-size: 16px;
  text-transform: lowercase;
  margin-left: 12px;
}
.pfad a {
  margin: 0 12px;
}
.openmenu {
  float: left;
  cursor: pointer;
  color: #454446;
  font-size: 16px;
}
.layout3 .openmenu {
  color: #fff;
}
.layout3 .openmenu:hover,
.layout3 .openmenu:focus {
  color: #454446;
}
.closenavi {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 15px;
  top: 28px;
  background: url(/images/closenavi.svg) no-repeat 0 0;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
.closenavi:hover,
.closenavi:focus {
  opacity: 0.6;
  cursor: pointer;
}
.closemenu {
  float: left;
  margin-left: 15px;
  cursor: pointer;
  font-size: 16px;
  color: #9d9c9c;
}
.closemenu:hover,
.closemenu:focus {
  color: #fff;
}
.navigation {
  float: left;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background: url(/images/navigation2.png) repeat 0 0;
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
  margin-top: -10000px;
}
.navigation.cb-toggle-target-active {
  -o-transition: all 0.218s ease-in;
  -moz-transition: all 0.218s ease-in;
  -webkit-transition: all 0.218s ease-in;
  transition: all 0.218s ease-in;
  margin-top: 0 !important;
}
.naviheight {
  float: left;
  width: 100%;
  margin-top: -27px;
  padding-bottom: 39px;
}
.content {
  float: left;
  width: 100%;
}
.layout3 .content {
  position: absolute;
  bottom: 0;
  left: 0;
}
.maincontent {
  float: left;
  width: 100%;
}
.footer {
  float: left;
  width: 100%;
  margin-top: 46px;
  border-top: 1px dotted #c2c2c3;
  position: relative;
}
.layout3 .footer {
  display: none;
}
.copyright {
  float: left;
  color: #6f7071;
  font-size: 10px;
  margin: 16px 0 56px 0;
}
img.zoom {
  width: 76px !important;
  height: 76px !important;
  margin: 0 !important;
  left: 50%;
  top: 50%;
  margin-top: -38px !important;
  margin-left: -38px !important;
}
.toplink {
  float: right;
  font-size: 10px;
  margin-top: 8px;
  margin-right: 1.25%;
  color: #6f7071;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  width: 30px;
  height: 30px;
  background: url(/images/topbutton.svg) no-repeat 0 0;
}
.toplink:hover,
.toplink:focus {
  cursor: pointer;
  color: #aaa3a4;
}
.meta.tell {
  position: absolute;
  top: -29px;
  right: 1.25%;
  font-size: 10px;
  color: #6f7071;
}
.meta.tell:hover,
.meta.tell:focus {
  cursor: pointer;
  color: #aaa3a4;
}
#shrFrm {
  max-width: 1169px;
}
.hometoggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 4;
}
.hometoggle:hover,
.hometoggle:focus {
  cursor: pointer;
}
#over {
  background: rgba(255, 255, 255, 0.9);
}
#disp .body {
  background: none;
  padding: 0;
}
#disp .body label,
#disp .body p {
  color: #fff;
}
#disp .foot {
  background: none;
}
#disp .head .cb-hybrid,
#disp a.quit {
  background: url(/icon-lightbox/lb-ctr.png) no-repeat 0 0;
  width: 12px;
  height: 20px;
}
#disp .head .next .cb-hybrid {
  background-position: -21px 0;
  margin-left: 20px;
}
#disp .head .quit a.quit {
  width: 16px;
  background-position: -50px 0;
}
.covid19 {
  position: absolute;
  right: 50%;
  margin-right: -740px;
  top: 300px;
  z-index: 3;
  background: url(/images/header.png) repeat 0 0;
  box-sizing: border-box;
  padding: 30px;
  color: #fff;
  width: 700px;
  text-align: right;
}
.covid19 strong {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.3;
}
@media (max-width: 1600px) {
  .covid19 {
    right: 40px;
    top: 260px;
    margin-right: 0;
  }
}
@media (max-width: 1023px) {
  .covid19 {
    width: 90%;
    right: 5%;
  }
}
.unit-filter {
  float: left;
  width: 102%;
  margin-left: -1%;
  margin-bottom: 40px;
  margin-top: 40px;
}
@media (max-width: 1023px) {
  .unit-filter {
    width: 104.54545455%;
    margin-left: -2.27272727%;
  }
}
@media (max-width: 767px) {
  .unit-filter {
    width: 100%;
    margin-left: 0;
  }
}
.filter-list {
  float: left;
  width: calc(100% + 12px);
  margin: -6px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .filter-list {
    margin: -3px;
  }
}
.filter-item {
  float: left;
  margin: 6px;
  width: calc(25% - 12px);
}
@media (max-width: 1023px) {
  .filter-item {
    width: calc(50% - 12px);
  }
}
@media (max-width: 767px) {
  .filter-item {
    margin: 3px;
    width: calc(50% - 6px);
  }
}
.filter-link {
  display: block;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  background-color: #c4c4c4;
  color: #000;
  font-size: 12px;
  line-height: 1.33333333;
  font-weight: normal;
  font-family: 'CenturyGothicBold', helvetica, sans-serif;
  padding: 10px 44px 10px 12px;
  border-radius: 5px;
  cursor: pointer;
  background-size: 20px 20px;
  background-position: right -20px center;
  background-repeat: no-repeat;
  background-image: url(/images/icon-cross.svg);
  -webkit-font-smoothing: antialiased;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.filter-link:hover,
.filter-link:focus {
  color: #000;
}
.filter-item.is-active .filter-link {
  color: #fff;
  background-position: right 12px center;
}
.aa-filter-unit {
  display: none !important;
  opacity: 0;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.aa-filter-unit.aa-filter-unit--active {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  opacity: 1;
  animation-name: blendIn;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}
@keyframes blendIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.section {
  float: left;
  width: 100%;
}
.cb-page-layout5 .maincontent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
}
.maincontent__section {
  float: left;
  width: 49.5%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
@media (max-width: 1023px) {
  .maincontent__section {
    width: 100%;
  }
}
.backlink {
  position: absolute;
  left: 1%;
  top: 0;
  width: 35px;
  height: 35px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/backlink.svg);
  cursor: pointer;
  display: none;
}
.cb-page-layout5 .backlink {
  display: block;
}
.cb-page-layout5 .cb-page-title {
  float: left;
  width: 95.95959596%;
  margin: 0 2.02020202%;
}
.cb-page-layout5 .cb-page-title h1 {
  margin: 0 !important;
  text-align: left;
}
@media (max-width: 1023px) {
  .cb-page-layout5 .cb-page-title {
    margin-top: 80px;
  }
}
.cb-page-layout5 .south {
  margin-bottom: -20px;
  text-align: left;
}
@media (max-width: 1023px) {
  .cb-page-layout5 .south {
    margin-bottom: 0;
  }
}
.cb-page-layout5 .south .unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.cb-page-layout5 .south .unit .part {
  margin-top: 10px;
  margin-bottom: 10px;
}
.cb-page-layout5 .south .unit.wide .pict.tall {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
h1 {
  text-align: right;
  font-weight: normal;
  font-size: 25px;
  color: #6f7071;
  text-transform: lowercase;
}
h1 strong {
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'CenturyGothicRegular', helvetica, sans-serif;
}
h2 {
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: #6f7071;
}
#disp h2 {
  text-transform: lowercase;
  font-size: 16px;
  color: #454446;
}
strong {
  font-weight: normal;
  font-family: 'CenturyGothicBold', helvetica, sans-serif;
}
.loud {
  padding: 0.2em 0.4em;
  background-color: #bfbfbf;
  color: #fff;
  /* redo with @basecolor & guard */
}
.loud > .open {
  color: #e6e6e6;
  /* redo with @basecolor */
}
.loud > .open:hover,
.loud > .open:focus {
  color: #bfbfbf;
}
.pale {
  color: #929093;
}
.skew {
  letter-spacing: 0.03em;
  font-style: italic;
  font-family: georgia, serif;
}
.text {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  list-style: disc;
}
a {
  color: #6f7071;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #a2a3a4;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.4em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-position: 0 0.3em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
div.area {
  text-align: right;
}
div.main div.unit,
div.side div.unit {
  margin-top: 0;
  margin-bottom: 0;
}
div.main div.unit div.part,
div.side div.unit div.part {
  margin-top: 6px;
  margin-bottom: 6px;
}
.cb-page-layout4 .main {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}
div.seam strong {
  font-weight: normal;
  color: #6f7071;
  font-family: 'CenturyGothicRegular', helvetica, sans-serif;
}
#view div.seam div.text {
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: #fff;
  z-index: 2;
}
#view div.seam div.text a {
  color: #fff;
}
#view div.seam div.text a:hover,
#view div.seam div.text a:focus {
  color: #6f7071;
}
div.cb-slideshow div.head {
  position: static;
}
div.cb-slideshow div.head h2 {
  padding: 0;
  min-height: 0;
}
div.cb-slideshow div.head div.ctrl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 0;
  position: static;
}
div.cb-slideshow div.head div.ctrl div.prev {
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
}
div.cb-slideshow div.head div.ctrl div.prev a {
  width: 50px;
  height: 100%;
  display: block;
  padding-left: 20px;
  background: url(/images/album-prev2.svg) no-repeat 100% 50%;
  background-size: 50px auto;
}
div.cb-slideshow div.head div.ctrl div.prev a.fade {
  display: none;
}
div.cb-slideshow div.head div.ctrl div.next {
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 2;
}
div.cb-slideshow div.head div.ctrl div.next a {
  width: 50px;
  height: 100%;
  display: block;
  padding-right: 20px;
  background: url(/images/album-next2.svg) no-repeat 0 50%;
  background-size: 50px auto;
}
div.cb-slideshow div.head div.ctrl div.next a.fade {
  display: none;
}
.cb-page-layout4 .main .unit {
  text-align: left;
}
.cb-page-layout4 .main .unit .pict.tall {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.cb-page-layout4 .main .unit .text p.norm {
  font-size: 12px;
  line-height: 1.16666667;
}
.cb-page-layout4 .main .unit .text p.norm i {
  color: #e30613;
  font-style: normal;
  font-weight: normal;
  font-family: 'CenturyGothicBold', helvetica, sans-serif;
}
.grow {
  text-transform: none !important;
}
.container-svk {
  float: left;
  width: 100%;
}
.desk--svk {
  position: relative;
  z-index: 2;
}
.desk--svk a {
  display: block;
  width: 48.33333333%;
  max-width: 580px;
}
.desk--svk a .svk {
  width: 100%;
  height: auto;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 2.2em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #454446;
  background-color: #fff;
  font-weight: normal;
  font-family: 'CenturyGothicRegular', helvetica, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.11666667em 3px;
  min-height: 2.2em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 12px;
  font-size: 1.2rem;
  color: #444;
  font-weight: normal;
  font-family: 'CenturyGothicRegular', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #929093;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.11666667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #626364 #494a4a #494a4a #626364;
  border-radius: 2px;
  background-color: #6f7071;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(60, 61, 62, 0.75);
  line-height: 2.2em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #626364 #494a4a #494a4a #626364;
  background-color: #777879;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #626364 #494a4a #494a4a #626364;
  background-color: #565757;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 2.2em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.cb-multimood {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cb-expo {
  float: left;
  width: 100%;
  position: relative;
  max-width: 1600px;
}
.cb-slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 62.5%;
  position: relative;
}
.cb-page-layout5 .cb-slides {
  padding-bottom: 100%;
}
.cb-slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
.cb-slides a {
  display: block;
  width: 100%;
  height: 100%;
}
.cb-slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
.cb-slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-expo div.link {
  float: left;
  width: 100%;
  height: 0;
}
.cb-page-selected-layout3 .cb-expo div.link {
  display: none;
}
.cb-expo a.link {
  position: absolute;
  bottom: 12px;
  z-index: 2;
  width: 36px;
  height: 36px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/mood-prev.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-expo a.link:hover,
.cb-expo a.link:focus {
  transform: scale(0.9);
}
.cb-expo a.link.prev {
  right: 56px;
}
.cb-expo a.link.next {
  right: 12px;
  background-image: url(/images/mood-next.svg);
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
  display: none;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #6f7071;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'CenturyGothicRegular', helvetica, sans-serif;
  line-height: 1.42857143;
  color: #454446;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  display: block !important;
  transform: none;
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head {
  padding: 0 5vw;
  box-sizing: border-box;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #6f7071;
  font-size: 16px;
  font-weight: normal;
  font-family: 'CenturyGothicBold', helvetica, sans-serif;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
  margin-left: 10px;
}
#disp .foot input,
#disp .foot a {
  float: left;
  line-height: 1.42857143;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input[type*="radio"],
#disp input[type*="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
/*** mobile-navi-multilevel-right-left.less 2014-11-03 ***/
.navi,
#find,
.dock {
  display: none;
}
#mobile-navi {
  position: fixed;
  top: 0;
  width: 240px;
  height: 100%;
  z-index: 2000;
  overflow: scroll;
  background: url(/images/navigation.png) repeat 0 0;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.togglenavigation {
  position: fixed;
  top: 40px;
  display: block;
  width: 40px;
  height: 20px;
  z-index: 2000;
  cursor: pointer;
  color: #454446 !important;
  -webkit-tap-highlight-color: transparent;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  -webkit-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  background: #fff;
  padding: 10px 5px;
  margin-left: -5px;
}
.togglenavigation:active > span {
  background: #313031 !important;
}
.togglenavigation > span {
  left: 5px;
  top: 50%;
  position: absolute;
  display: block;
  height: 4px;
  width: 40px;
  margin-top: -2px;
  background: currentColor;
}
.togglenavigation > .tline-1 {
  margin-top: -10px;
}
.togglenavigation > .tline-4 {
  margin-top: 6px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  -webkit-transition: -webkit-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -o-transition: -o-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -moz-transition: -moz-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.cb-toggle-navi-options {
  position: relative;
  z-index: 2000;
}
.navigation.cb-toggle-target-active #mobile-navi {
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
}
.navigation.cb-toggle-target-active .togglenavigation {
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  -webkit-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
}
/* RIGHT */
/* LEFT */
#mobile-navi {
  left: -240px;
}
.togglenavigation {
  left: 5%;
}
.navigation.cb-toggle-target-active #mobile-navi {
  left: 0;
}
.navigation.cb-toggle-target-active .togglenavigation {
  margin-left: 240px;
}
#mobile-navi .navi {
  float: left;
  display: block;
  width: 90%;
  margin-left: 5%;
  margin-top: 30px;
  margin-bottom: 30px;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
}
#mobile-navi .navi .item .menu {
  text-transform: lowercase;
  display: block;
  color: #fff;
  padding: 8px 10px;
  padding-right: 35px;
  font-size: 16px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
#mobile-navi .navi .item .menu.path {
  background: rgba(255, 255, 255, 0.4);
}
#mobile-navi .navi.sub3 {
  max-height: 0;
  overflow: hidden;
  width: 100%;
  margin: 0;
}
#mobile-navi .navi.sub3 .item .menu {
  font-size: 13px;
  padding-left: 20px;
  padding-right: 10px;
  border-left: 10px solid transparent;
}
#mobile-navi .navi.sub3 .item .menu.path {
  border-left: 10px solid rgba(255, 255, 255, 0.4);
  background: none;
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  max-height: 1000px;
  -o-transition: all 2s;
  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  transition: all 2s;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 35px;
  height: 35px;
  background: url(/images/mnavi.svg) no-repeat 50% 50%;
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active {
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
#mobile-navi div.sub3 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
/******* layout-small.less 2013-1-16 *******/
.desk {
  max-width: 90%;
}
.l3home {
  margin-left: auto;
  margin-right: auto;
  float: none;
  max-width: 90%;
}
.navihome,
.cb-home {
  width: 200px;
}
.menupfad,
.navigation .desk {
  display: none;
}
.navigation {
  height: 0;
}
.copyright {
  margin-bottom: 0;
}
#services {
  float: left;
  width: 100%;
  display: block;
  margin: 0 0 20px 0;
}
#services .meta {
  font-size: 10px;
  margin-right: 10px;
}
#services .meta.firm {
  display: none;
}
.cb-page-last #services .meta.firm {
  display: inline;
}
#services .meta.auth {
  display: none;
}
.content .cb-slideshow > .body-mobile img.zoom {
  left: 50% !important;
  top: 50% !important;
  width: 76px !important;
  height: 76px !important;
  margin-top: -38px !important;
  margin-left: -38px !important;
}
div.ps-carousel {
  background: #000;
}
@media only screen and (max-width: 767px) {
  #services {
    display: none;
  }
  .toplink {
    margin-bottom: 56px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 100%;
}
h1,
h2 {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.desk--svk a {
  margin-top: 10px;
  width: 86.80555556%;
  max-width: 356px;
}
/* scan.less 2013-1-16 */
.scan .cb-hybrid {
  color: #454446;
  text-decoration: none;
}
.scan .cb-hybrid:hover,
.scan .cb-hybrid:focus {
  color: #121212;
}
.swap {
  text-align: center;
}
.scan form,
.scan > table,
.scan fieldset {
  width: 100% !important;
}
.scan > table {
  margin: 0 !important;
}
.swap td > span,
.swap td > .cb-hybrid {
  display: block;
}
td.prev,
td.next {
  color: rgba(69, 68, 70, 0.3);
}
.unit.cb-batch .same {
  background-color: #565757;
  color: #454446;
}
/******* module-album.less 2013-1-16 *******/
.body-mobile img,
noscript img {
  /* resize - noscript mode must still work */
  width: 100%;
}
img.same {
  background: none !important;
}
div.foto div.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.cb-mobile .cb-slideshow > .head > .ctrl {
  display: none;
}
.cb-slideshow > .body-mobile img.zoom {
  top: 100% !important;
  left: 100% !important;
  display: block !important;
  margin: -20px 0 0 -20px !important;
  width: 17px !important;
  height: 17px;
  opacity: 0.7;
}
div.foto,
.crossslideContainer {
  position: relative;
}
.crossslide,
.cb-slideshow-images img {
  width: 100%;
}
.cb-slideshow-images {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.cb-strips .foto > .cb-landscape,
.cb-matrix .body-mobile .foto > img,
.cb-contentflow .foto > img,
.cb-slideshow .foto > img,
.body-non-mobile .foto > img {
  margin: 0 auto;
  max-width: 100% !important;
  width: 100%;
  max-height: 100% !important;
}
.cb-strips .foto > .cb-portrait {
  height: 100%;
  width: auto;
}
.body-mobile a.foto,
.body-mobile span.foto {
  width: 100%;
}
noscript a.foto {
  position: relative !important;
}
.body-mobile .cb-matrix .foto,
.body-mobile .cb-contentflow .foto {
  position: relative;
}
.part.desc {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.same.norm,
.same.foto {
  background: none;
}
.body-non-mobile a.foto,
.body-non-mobile span.foto {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  padding: 0;
  width: 100%;
}
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  width: 18px;
}
.prev > .prev,
.next > .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  display: block;
  height: 18px;
}
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  background-image: url('/icons/prev_next.png');
  background-repeat: no-repeat;
}
.cb-slideshow .ctrl > .prev,
.cb-slideshow .ctrl > .next {
  float: left;
}
.cb-album .prev {
  background-position: 0px -54px;
}
.cb-strips td.prev,
.cb-strips td.next {
  background: none;
  vertical-align: middle;
}
.cb-album a.prev,
.cb-album .prev > .cb-hybrid {
  background-position: 0px 0px;
}
.cb-album a.prev:hover,
.cb-album .prev > .cb-hybrid:hover {
  background-position: 0px -18px;
}
.cb-album a.prev:active,
.cb-album .prev > .cb-hybrid:active {
  background-position: 0px -36px;
}
.cb-album .prev > .cb-hybrid.fade {
  background-position: 0px -54px;
}
.cb-album .next {
  background-position: -17px -54px;
}
.cb-album a.next,
.cb-album .next > .cb-hybrid {
  background-position: -17px 0px;
}
.cb-album a.next:hover,
.cb-album .next > .cb-hybrid:hover {
  background-position: -17px -18px;
}
.cb-album a.next:active,
.cb-album .next > .cb-hybrid:active {
  background-position: -17px -36px;
}
.cb-album .next > .cb-hybrid.fade {
  background-position: -17px -54px;
}
.cb-strips table {
  border-collapse: collapse;
}
td > .body {
  display: inline-block;
  vertical-align: middle;
}
.cb-strips-images {
  height: 100%;
  width: 100%;
}
.cb-strips-images > .foto {
  width: 100%;
  height: 100%;
  position: absolute;
}
.cb-strips-images > .foto > .cb-landscape {
  width: 100%;
  height: auto;
}
.cb-strips-images > .foto > .cb-portrait {
  width: auto;
  height: 100%;
}
.cb-strips-container > .body > .foto {
  height: 100% !important;
  overflow: hidden;
}
.cb-strips-thumbs a.foto {
  width: 100%;
}
.cb-strips-container > .body a.foto,
.cb-strips-thumbs .part > .foto > .foto {
  position: static;
  width: 100% !important;
  height: 100% !important;
}
.cb-strips-container .cb-landscape,
.cb-strips-container .cb-portrait {
  max-width: 100% !important;
  max-height: 100% !important;
}
.cb-strips-4 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 23% !important;
}
.cb-strips-6 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 14.66666667% !important;
}
.cb-strips-8 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 10.5% !important;
}
.cb-strips-10 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 8% !important;
}
.cb-matrix .body-non-mobile > .foto {
  margin: 3% !important;
}
.cb-matrix .body-non-mobile a.foto {
  position: static;
  height: 100% !important;
}
.cb-matrix-columns-1 .body-non-mobile > .foto {
  width: 94% !important;
}
.cb-matrix-columns-3 .body-non-mobile > .foto,
.cb-matrix-columns-5 .body-non-mobile > .foto,
.cb-matrix-columns-6 .body-non-mobile > .foto,
.cb-matrix-columns-7 .body-non-mobile > .foto,
.cb-matrix-columns-8 .body-non-mobile > .foto {
  width: 44% !important;
}
@media only screen and (min-width: 240px) {
  .cb-matrix-columns-2 .body-non-mobile > .foto,
  .cb-matrix-columns-4 .body-non-mobile > .foto,
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 44% !important;
  }
  .cb-matrix-columns-6 .body-non-mobile > .foto,
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 10.66666667% !important;
  }
}
@media only screen and (min-width: 768px) {
  .cb-matrix-columns-3 .body-non-mobile > .foto {
    width: 27.33333333% !important;
  }
  .cb-matrix-columns-4 .body-non-mobile > .foto,
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 19% !important;
  }
  .cb-matrix-columns-5 .body-non-mobile > .foto,
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 14% !important;
  }
  .cb-matrix-columns-6 .body-non-mobile > .foto {
    width: 10.66666667% !important;
  }
}
@media only screen and (min-width: 1024px) {
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 8.28571429% !important;
  }
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 6.5% !important;
  }
}
.cb-contentflow .body-non-mobile .part {
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
}
.foto > .head {
  position: relative;
}
.cb-manual .desc,
.body-non-mobile > .desc {
  position: relative;
}
#view .cb-manual .desc .norm,
#view .body-non-mobile > .desc .norm {
  position: absolute;
  top: 0;
  left: 0;
}
.foto > .head > .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
}
.cb-album .ctrl {
  float: right;
}
#root div.foto div.body div.desc {
  float: left;
}
#root div.foto div.body div.desc div.part {
  float: none;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */